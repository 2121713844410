<!-- 创建员工 -->
<template>
  <div>
    <div class="box">
      <p class="box-user">员工角色</p>
      <div class="user-list">


        <div class="user-item" v-for="(item, index) in roleList" :key="index">
          <img :src="item.empHeader" alt="" class="user-head" :class="{ 'head-active': selectIndex == index }"
            @click="changeSelectHead(index)">
          <p class="user-name">{{ item.empType }}</p>


          <el-upload action="" :show-file-list="false" :before-upload="beforeUpload"
            :http-request="(params) => httpRequest1(params, index)">
            <p class="upload-head" :class="{ 'up-active': selectIndex == index }">自定义头像</p>
          </el-upload>




        </div>
        <div style="flex: 1;"></div>
        <div class="modifybg" @click="showCustomDialog">
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/upbgicon.png">
          <p>移动端个性化编辑</p>
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/yyt_hui.png">
        </div>

      </div>
      <div class="div-form">

        <p class="div-form-title"><i>*</i>员工名称：</p>
        <el-input type="text" placeholder="请输入员工名称" v-model="empName" maxlength="6" show-word-limit>
        </el-input>
      </div>
      <div class="div-form">
        <p class="div-form-title"><i>*</i>公司名称：</p>
        <el-input type="text" placeholder="请输入公司名称" v-model="companyName" maxlength="10" show-word-limit>
        </el-input>
      </div>
      <div class="div-form">
        <p class="div-form-title"><i>*</i>员工介绍：</p>
        <el-input type="text" placeholder="请输入员工介绍" v-model="remark" maxlength="13" show-word-limit>
        </el-input>
      </div>

      <div class="div-form">
        <p class="div-form-title">角色定义</p>
        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请为员工添加角色定义，例如：你是一个营销专家，熟悉营销行业所有知识…"
          v-model="empTypePrompt" maxlength="3000" show-word-limit>
        </el-input>
      </div>

      <div class="div-form">
        <p class="div-form-title">主动打招呼话术：</p>
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容" v-model="replyPrompt"
          maxlength="300" show-word-limit>
        </el-input>
      </div>

      <div class="div-form div-peizhi">
        <p class="p-pz1">配置推荐问题：</p>
        <p class="p-pz2">仅在员工的第一句主动打招呼话术下展示</p>
        <el-popover placement="top-start" trigger="hover" :visible-arrow="false" popper-class="my-popover">

          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/pzwt1.png" class="peizhiwt1">

          <p class="p-pz3" slot="reference">示例</p>
        </el-popover>

        <div class="div-pz1">
          <el-checkbox v-model="textQuestion"></el-checkbox>
          <p>有问答文档时，优先使用文档内容自动推荐问题</p>

          <el-switch v-model="isConfigQuestion" :width="32" active-color='#226CFF' @change="changeSwitch1">
          </el-switch>
        </div>
      </div>

      <div class="div-addpz" v-if="isConfigQuestion">

        <div class="pz-input" v-for="(item, index) in pzList">
          <el-input type="text" placeholder="请输入问题内容" v-model="pzList[index]" maxlength="100">
          </el-input>
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/pzdelete.png" class="pz-delete"
            @click="deletePz(index)">
        </div>

        <div class="btn-addpz" @click="addPz" v-if="pzList.length < 5">
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/pzadd.png">
          <p>新增一项</p>

        </div>
      </div>

      <div class="div-form div-peizhi">
        <p class="p-pz1">AI生成推荐问题：</p>
        <p class="p-pz2">开启后，员工的每一条回答下都会自动生成3～5条推荐问题</p>
        <el-popover placement="top-start" trigger="hover" :visible-arrow="false" popper-class="my-popover">

          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/pzwt2.png" class="peizhiwt2">

          <p class="p-pz3" slot="reference">示例</p>
        </el-popover>

        <div class="div-pz1">



          <el-switch v-model="isReplyprompt" :width="32" active-color='#226CFF' @change="changeSwitch2">
          </el-switch>
        </div>



      </div>

      <div class="div-sub">

        <el-button type="primary" :disabled="!(empName && companyName && remark)" @click="addWorker"
          :loading="btnloading">{{ btnTxt }}</el-button>

        <button class="cancle-btn" v-if="empId" @click="cancleEdit">
          取消编辑
        </button>
      </div>


    </div>
    <PublishDialog ref="childShare"></PublishDialog>

    <CustombgDialog ref="childShare2" @customAction="customAction"></CustombgDialog>

    <el-dialog :visible.sync="dialogshow" :show-close="false">
      <div class="div-dialog">
        <div class="dialog-head">
          <p class="dialog-head-p">操作提示</p>
          <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
            @click="dialogConfim">
        </div>

        <div class="dialog-content">

          <p>{{ dialogcontent }}</p>

        </div>

        <div class="dialog-bottom">

          <button class="dialog-negative" @click="dialogConfim">取消</button>
          <button class="dialog-positive" @click="dialogConfim1">确认</button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import common from "common/js/common";
import { recommendEmp, addEmp, findEmpById, fileUpload } from "api/aiworker/aiworker.js";
import PublishDialog from "./cells/PublishDialog.vue";
import CustombgDialog from "./cells/Custombg.vue";
export default {
  components: {

    PublishDialog,
    CustombgDialog

  },
  data () {
    return {
      title: "",
      dialogshow: false,
      dialogType: 1,
      dialogcontent: "确定将推荐提问关闭吗？",
      btnTxt: "",
      roleList: [],
      selectIndex: 0,
      empName: '',
      companyName: '',
      empTypePrompt: '',
      remark: '',
      replyPrompt: '您好，我是您的小助理机器人，有什么问题可以问我，我会尽力为您解答！',
      empId: null,
      empId1: null,
      empTypeId: "",
      empType: "",
      btnloading: false,
      orgId: null,
      pzList: [''],
      configQuestionList: [],
      isConfigQuestion: false,
      isReplyprompt: false,
      textQuestion: false,

      editHeadFlag: false,
      empHeader: null,
      customObj: {
      },
    }
  },
  methods: {

    showDialog (parm, parm2) {

      this.$refs.childShare.showPopup1(parm, parm2);
    },

    showCustomDialog () { 

      this.$refs.childShare2.showPopup1(this.roleList[this.selectIndex].empHeader,this.customObj);
    },
    customAction (obj) { 
      if (!common.isStringEmpty(obj.backGroundImg)) { 
        this.customObj.backGroundImg = obj.backGroundImg;
      }
      if (!common.isStringEmpty(obj.mainTitle)) { 
        this.customObj.mainTitle = obj.mainTitle;
      }
      if (!common.isStringEmpty(obj.oneRemark)) { 
        this.customObj.oneRemark = obj.oneRemark;
      }
    },
    dialogConfim1 () {
      this.dialogshow = false;
      if (this.dialogType == 3) {
        window.close();
      } 
    },
    dialogConfim () {
      this.dialogshow = false;
      if (this.dialogType == 2) {
        this.isReplyprompt = true;
      } else if (this.dialogType == 3) { 
        
      } else{
        this.isConfigQuestion = true;
      }
    },
    changeSwitch2 (flag2) {
      if (flag2 == false) {
        this.dialogType = 2;
        this.dialogshow = true;
      }
    },
    changeSwitch1 (flag2) {
      if (flag2 == false) {
        this.dialogType = 1;
        this.dialogshow = true;
      }
    },

    beforeUpload (file) {
      let fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
      if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg') {
        // 处理符合要求的文件     
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
          return false;
        }

      } else {
        this.$message.error('请上传正确格式的图片');
        return false;
      }



    },
    httpRequest1 (file2, currentI) {

      console.log(currentI + "---" + file2);
      let fd = new FormData();
      fd.append('access_token', this.$localstore.get("access_token"));
  
      fd.append('files',file2.file)
      fileUpload(fd).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          this.editHeadFlag = true;
          this.empHeader = res.list[0];
          this.roleList[currentI].empHeader = this.empHeader;

        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          } else {
            this.$message.error('上传失败，请重试！');
          }

        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message.error(JSON.stringify(err.message));
        }

      });

    },

    changeSelectHead (index) {
      this.selectIndex = index;
      this.empTypeId = this.roleList[index].id
      this.empType = this.roleList[index].empType
      this.empTypePrompt = this.roleList[index].empTypePrompt;
    },
    cancleEdit () {
      //history.back()
      if (this.editHeadFlag) {
        this.dialogshow = true;
        this.dialogType = 3;
        this.dialogcontent = "取消后，您的定制化头像或背景将不能生效，是否取消？"
      } else { 
        window.close();
      }

      
    },
    addWorker () {
      this.btnloading = true;
      let opo = {
        userId: this.$localstore.get("hdUserId"),
        empId: this.empId,
        empName: this.empName,
        companyName: this.companyName,
        replyPrompt: this.replyPrompt,
        remark: this.remark,
        empTypeId: this.empTypeId,
        empType: this.empType,
        empTypePrompt: this.empTypePrompt,
      };
      if (this.isConfigQuestion) {
        opo.isConfigQuestion = 1;
      } else {
        opo.isConfigQuestion = 0;
      }
      if (!common.isStringEmpty(this.empHeader)) {
        opo.empHeader = this.empHeader;
      }
      if (!common.isStringEmpty(this.customObj.backGroundImg)) {
        opo.backGroundImg = this.customObj.backGroundImg;
      }
      if (!common.isStringEmpty(this.customObj.mainTitle)) {
        opo.mainTitle = this.customObj.mainTitle;
      }
      if (!common.isStringEmpty(this.customObj.oneRemark)) {
        opo.oneRemark = this.customObj.oneRemark;
      }


      if (this.isReplyprompt) {
        opo.isReplyprompt = 1;
      } else {
        opo.isReplyprompt = 0;
      }
      if (this.textQuestion) {
        opo.textQuestion = 1;
      } else {
        opo.textQuestion = 0;
      }
      this.configQuestionList = [];
      for (let i = 0; i < this.pzList.length; i++) {
        if (!common.isStringEmpty(this.pzList[i])) {
          this.configQuestionList.push(this.pzList[i]);
        }
      }
      opo.configQuestionList = this.configQuestionList;
      console.log(opo);
      addEmp(opo).then((res) => {
        console.log(res);
        this.btnloading = false;
        if (res.code == 200) {
          this.empId1 = res.data.empId;
          this.orgId = res.data.orgId;
          if (!common.isStringEmpty(this.empId)) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            //window.location.href = "https://www.baidu.com/"
            //跳转首页 刷新
            var reload = localStorage.getItem('reloadWorkbenches');
            localStorage.setItem('reloadWorkbenches', parseInt(reload) + 1);
            window.close();

          } else {
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.showDialog(this.empId1, this.orgId);
          }

        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {
        this.btnloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });
    },
    addPz () {

      this.pzList.push('');
    },
    deletePz (i) {
      this.pzList.splice(i, 1)
    }

  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

    // console.log(navigator.userAgent.replace(/[^\w]/gi, ''));
    recommendEmp().then((res) => {
      if (res.code == 200) {
        console.log(res);
        this.roleList = res.data;

        if (!common.isStringEmpty(this.$route.query.eid)) {
          this.title = "编辑该数字员工";
          this.btnTxt = "保存内容";
          // this.empId = this.$route.query.eid;
          this.empId = this.$Base64.decode(this.$route.query.eid);
          findEmpById(this.empId).then((res) => {
            console.log(res);
            if (res.code == 200) {

              this.empName = res.data.empName;
              this.companyName = res.data.companyName;
              this.remark = res.data.remark;
              this.replyPrompt = res.data.replyPrompt;
              this.empTypeId = res.data.empTypeId;
              this.empType = res.data.empType;
              this.empTypePrompt = res.data.empTypePrompt;

              for (var i = 0; i < this.roleList.length; i++) {

                if (this.empTypeId == this.roleList[i].id) {
                  this.selectIndex = i;
                  break;
                }
              }
              if (!common.isStringEmpty(res.data.realEmpHeader)) {
                this.empHeader = res.data.realEmpHeader;
                this.roleList[this.selectIndex].empHeader = this.empHeader;
              }

              if (!common.isStringEmpty(res.data.backGroundImg)) {
                this.customObj.backGroundImg = res.data.backGroundImg;
              }

              if (!common.isStringEmpty(res.data.mainTitle)) {
                this.customObj.mainTitle = res.data.mainTitle;
              }

              if (!common.isStringEmpty(res.data.oneRemark)) {
                this.customObj.oneRemark = res.data.oneRemark;
              }

              if (res.data.isReplyprompt == 1) {
                this.isReplyprompt = true;
              } else {
                this.isReplyprompt = false;
              }
              if (res.data.isConfigQuestion == 1) {
                this.isConfigQuestion = true;
              } else {
                this.isConfigQuestion = false;
              }
              if (res.data.textQuestion == 1) {
                this.textQuestion = true;
              } else {
                this.textQuestion = false;
              }
              if (res.data.configQuestionList != null && res.data.configQuestionList.length > 0) {
                this.pzList = res.data.configQuestionList;
              }

            } else {
              if (!common.isStringEmpty(res.message)) {
                this.$message(res.message);
              }


            }
          }).catch((err) => {

          });
        } else {
          this.title = "开始创建数字员工";
          this.btnTxt = "发布员工";
          this.empTypeId = this.roleList[0].id
          this.empType = this.roleList[0].empType
          this.empTypePrompt = this.roleList[0].empTypePrompt;
        }


      } else {

        if (!common.isStringEmpty(res.message)) {
          this.$message(res.message);
        }

      }
    }).catch((err) => {

      if (!common.isStringEmpty(JSON.stringify(err))) {
        this.$message(JSON.stringify(err.message));
      }
    });
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
i {
  width: 9px;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FB4061;
  line-height: 16px;
}

.box {

  display: flex;
  flex-direction: column;

}

.box-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  padding-top: 40px;
  margin-left: 60px;
  line-height: 34px;
}

.box-user {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-left: 60px;
  line-height: 16px;

}

.user-list {

  margin-top: 14px;
  margin-bottom: 10px;
  display: flex;
  margin-left: 60px;
}

.user-item {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 24px;
}

.modifybg {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 186px;
  height: 46px;
  background: #F4F7F9;
  border-radius: 8px 8px 8px 8px;
  margin-right: 60px;
  margin-top: 12px;
}

.modifybg p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  line-height: 14px;
}

.modifybg :nth-child(1) {
  width: 24px;
  height: 24px;
  margin-right: 2px;
}

.modifybg :nth-child(3) {
  width: 14px;
  height: 14px;
  margin-left: 4px;
}

.user-head {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  cursor: pointer;
}

.head-active {
  border: 2px solid #0679FF;
}

.up-active {
  display: block !important;
}

.user-name {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 14px;
  margin-top: 8px;
}

.upload-head {
  display: none;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #226CFF;
  line-height: 14px;
  margin-top: 4px;
  cursor: pointer;
}

.div-form {
  margin: 20px 60px 0px 60px;
}

.div-form-title {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

}



/**改变input里的字体颜色*/
/deep/ input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

/deep/ .el-input__inner {
  height: 52px;
  line-height: 52px;
  font-size: 16px;
  background-color: #F9FAFB;
  border-radius: 10px 10px 10px 10px;
  color: #333333;
  border: 1px solid #D7E4F3;
}

/deep/ .el-input__inner:focus {
  border-color: #409EFF;
  outline: 0;
}

/deep/ .el-textarea__inner {
  height: 120px;
  font-size: 16px;
  background-color: #F9FAFB;
  border-radius: 10px 10px 10px 10px;
  color: #333333;
  border: 1px solid #D7E4F3;
}

/deep/ .el-textarea__inner:focus {
  border-color: #409EFF;
  outline: 0;
}

.div-sub {

  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  margin-top: 30px;
  margin-right: 60px;


}

.cancle-btn {
  width: 280px;
  height: 52px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #226CFF;
  font-size: 16px;
  background-color: #ffffff;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #226CFF;
  text-align: center;
  margin-right: 15px;
}

/deep/ .el-button {
  width: 280px;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

/deep/.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {

  background-color: #a0cfff;
  border-color: #a0cfff;
}

.div-peizhi {

  display: flex;
  align-items: center;
}

.p-pz1 {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}

.p-pz2 {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.p-pz3 {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #226CFF;
  border-bottom: 1px solid #226CFF;
  cursor: pointer;
  margin-left: 10px;
}

.div-pz1 {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.div-pz1 p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

  margin-left: 4px;
  margin-right: 22px;

}

.div-pz1 /deep/ .el-checkbox__inner {
  width: 14px;
  height: 14px;

}

.div-pz1 /deep/ .el-checkbox__inner::after {
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  left: 5px;
  top: 2px;
}

.div-pz1 /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(50deg) scaleY(1.2);
}


.peizhiwt1 {

  width: 272px;
  height: 302px;
  border-radius: 10px 10px 10px 10px;
}

.peizhiwt2 {

  width: 272px;
  height: 381px;
  border-radius: 10px 10px 10px 10px;
}

.div-addpz {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.pz-input {
  display: flex;
  padding-left: 60px;
  align-items: center;
  margin-top: 10px;

}

.pz-delete {
  width: 20px;
  height: 20px;
  margin-right: 32px;
  margin-left: 8px;
}

.btn-addpz {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 48px;
  background: #EAF1F9;
  border-radius: 10px 10px 10px 10px;
  margin-left: 60px;
  margin-top: 10px;


}

.btn-addpz img {
  width: 18px;
  height: 18px;
}

.btn-addpz p {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #226CFF;
  margin-left: 5px;
}


.div-dialog {
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
}

.close_dialog {
  width: 36px;
  height: 36px;

  align-self: flex-end;
  position: absolute;
  right: 12px;

}

.dialog-head {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.dialog-head-p {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

}

.dialog-content {
  display: flex;
  width: 100%;
  flex: auto;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;


}

.dialog-bottom {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 19px;
  margin-right: 19px;
}

.dialog-negative {
  flex: auto;
  height: 52px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 52px;
  text-align: center;
}



.dialog-positive {
  flex: auto;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  border: none;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin-left: 15px;
}

/deep/.el-dialog {

  width: 400px;
  height: 260px;
  background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}

/deep/.el-dialog__header {

  display: none;

}

/deep/.el-dialog__body {

  height: 100%;
  padding: 0;
}</style>