<!--  -->
<template>
  <div>
    <div class="custombg">
      <el-dialog :visible.sync="show" :show-close="false" :before-close="handleClose" top="60px">
        <div class="div-dialog">
          <div class="drawer-head">
            <p>个性化编辑</p>
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png" @click="closeDialog(1)">
          </div>
          <div class="content">
            <div class="left-con">
              <div class="left-head">
                <p>*</p>
                <p>上传图片</p>
                <p>建议尺寸：750x418</p>
              </div>
              <el-upload action="" :show-file-list="false" :before-upload="beforeUpload" :http-request="httpRequest">
                <div class="left-upload">

                  <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/leftupload.png" class="leftup-icon">

                  <img :src="backGroundImg" class="left-imgbg" v-if="backGroundImg">
                </div>
              </el-upload>


              <p class="left-zhup">主标题</p>
              <el-input type="text" placeholder="请输入主标题…" v-model="mainTitle" maxlength="16" show-word-limit>
              </el-input>

              <p class="left-zhup">一句话介绍</p>
              <el-input type="text" placeholder="请输入简短的介绍…" v-model="oneRemark" maxlength="24" show-word-limit>
              </el-input>



            </div>
            <div class="right-con">

              <div class=img-box>
                <img class="imgbg1"
                  :src="backGroundImg == '' ? 'https://cdn.shhd.info/APP/sys_img/digitalEmployee/upygbg.png' : backGroundImg">
                  <div class="mohu-bg"></div>
                <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/upygbg2.png" class="imgbg2">
                
                <div class="biaoti-modi">

                  <p>{{ mainTitle }}</p>
                  <p>{{ oneRemark }}</p>
                </div>


                <img :src="headImg" class="right-head">
              </div>
              <p class="p-yulan">移动端预览</p>
            </div>


          </div>


          <div class="line"></div>
          <div class="bottom">

            <button class="cancle-btn" @click="closeDialog(2)">
              取消
            </button>
            <el-button type="primary"
              :disabled="backGroundImg == undefined || backGroundImg == null || backGroundImg.length == 0"
              @click="confimisImport">确认</el-button>

          </div>


        </div>

      </el-dialog>

    </div>
    <div class="tipdiaolog">
      <el-dialog :visible.sync="dialogshow" :show-close="false">
        <div class="div-dialog-tip">
          <div class="dialog-head-tip">
            <p class="dialog-head-p-tip">操作提示</p>
            <img class="close_dialog-tip" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
              @click="dialogshow = false">
          </div>

          <div class="dialog-content-tip">

            <p>{{ dialogcontent }}</p>

          </div>

          <div class="dialog-bottom-tip">

            <button class="dialog-negative" @click="dialogshow = false">取消</button>
            <button class="dialog-positive" @click="dialogConfim">确认</button>
          </div>
        </div>

      </el-dialog>
    </div>
  </div>
</template>

<script>
import common from "common/js/common";
import { fileUpload } from "api/aiworker/aiworker.js";
export default {
  data () {
    return {
      show: false,
      oneRemark1: '',
      mainTitle1: '',
      editFlag: false,
      dialogshow: false,
      dialogcontent: '',
      dialogType: 1,
      headImg: '',
      backGroundImg: '',
      mainTitle: '',
      oneRemark: '',
      customObj: {

      }

    }
  },



  methods: {

    handleClose (done) {

    },

    showPopup1 (param1, parm2) {
      this.headImg = param1;
     
      if (!common.isStringEmpty(parm2.backGroundImg)) {

        this.backGroundImg = parm2.backGroundImg;
      }
      console.log(parm2 + "--" + parm2.backGroundImg + "---" + this.backGroundImg);
      if (!common.isStringEmpty(parm2.mainTitle)) {
        this.mainTitle1 = parm2.mainTitle;
        this.mainTitle = parm2.mainTitle;
      }
      if (!common.isStringEmpty(parm2.oneRemark)) {
        this.oneRemark1 = parm2.oneRemark;
        this.oneRemark = parm2.oneRemark;
      }
      

      this.show = true;
    },
    closeDialog (i) {
      if (i == 1) {
        this.show = false;
      } else {
        if (this.editFlag) {
          this.dialogshow = true;
          this.dialogType = 1;
          this.dialogcontent = "是否确认取消定制内容？";
        } else {
          this.show = false;
        }

      }

    },
    dialogConfim () {
      this.dialogshow = false;
      this.show = false;
    },
    confimisImport () {

      if (this.mainTitle != this.mainTitle1) {

        this.editFlag = true;
      }

      if (this.oneRemark != this.oneRemark1) {

        this.editFlag = true;
      }
      this.show = false;
      if (this.editFlag) {
        this.customObj.backGroundImg = this.backGroundImg;
        this.customObj.mainTitle = this.mainTitle;
        this.customObj.oneRemark = this.oneRemark;
        this.$message('需发布员工或者保存内容后，定制化才生效');
        this.$emit('customAction', this.customObj)
      } else {
        this.show = false;
      }

    },
    beforeUpload (file) {
      let fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
      if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg') {
        // 处理符合要求的文件     
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传背景图片大小不能超过 2MB!');
          return false;
        }

      } else {
        this.$message.error('请上传正确格式的图片');
        return false;
      }



    },
    httpRequest (file2) {


      let fd = new FormData();
      fd.append('access_token', this.$localstore.get("access_token"));
      fd.append('files', file2.file)
      fileUpload(fd).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          this.editFlag = true;
          this.backGroundImg = res.list[0];



        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          } else {
            this.$message.error('上传失败，请重试！');
          }

        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message.error(JSON.stringify(err.message));
        }

      });

    },

  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */

.custombg /deep/.el-dialog {

  width: 900px;
  height: 615px;
  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;

}


.div-dialog {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.drawer-head {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.drawer-head p {
  flex: 1;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin-left: 30px;
}

.drawer-head img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.content {
  flex: 1;
  display: flex;
}

.left-con {


  width: 410px;
  display: flex;
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 40px;
  flex-direction: column;

}

.right-con {


  width: 410px;
  display: flex;
  margin-top: 15px;
  flex-direction: column;

}

.line {
  width: 100%;
  height: 1px;
  background: #edebeb;
}

.bottom {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: flex-end;
}

.cancle-btn {
  width: 160px;
  height: 52px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #D7E4F3;
  font-size: 16px;
  background-color: #ffffff;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin-right: 15px;
}

.bottom /deep/ .el-button {
  width: 160px;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  margin-right: 30px;
}

.img-box {
  position: relative;
  width: 394px;
  height: 360px;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 2px solid #0679FF;
  box-sizing: border-box;
}

.imgbg1 {
  border: 0px;
  width: 391px;
  height: 218px;
  border-radius: 12px 12px 12px 12px;
  object-fit: fill;

}

.imgbg2 {
  width: 390px;
  height: 166px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.p-yulan {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 14px;
  margin-top: 20px;
  align-items: center;
  align-self: center;
}

.right-head {
  width: 54px;
  height: 54px;
  border-radius: 27px;
  position: absolute;
  left: 27px;
  bottom: 81px;
}

.mohu-bg {
  width: 100%;
  height: 140px;
  position: absolute;
  top: 78px;
  background: linear-gradient(360deg, #E5F0FF 19%, rgba(229, 240, 255, 0) 100%);
}

.biaoti-modi {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 182px;
}

.biaoti-modi :nth-child(1) {
  font-size: 20px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin-left: 20px;
}

.biaoti-modi :nth-child(2) {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 13px;
  margin-top: 8px;
  margin-left: 20px;
}

.left-head {
  display: flex;
  align-items: center;
}

.left-head :nth-child(1) {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FB4061;
}

.left-head :nth-child(2) {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 16px;
}

.left-head :nth-child(3) {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 14px;
  margin-left: 10px;
}

.left-upload {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 167px;
  background: #F3F4F5;
  border-radius: 8px 8px 8px 8px;
  margin-top: 17px;
}

.left-imgbg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 300px;
  height: 167px;
  border-radius: 8px 8px 8px 8px;
}

.leftup-icon {
  width: 24px;
  height: 24px;
}

.left-zhup {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 16px;
  margin-top: 24px;
  margin-bottom: 10px;
}

/**改变input里的字体颜色*/
.left-con /deep/ input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
}

.left-con /deep/ .el-input__inner {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  background-color: #F9FAFB;
  border-radius: 10px 10px 10px 10px;
  color: #333333;
  border: 1px solid #D7E4F3;
}

.left-con /deep/ .el-input__inner:focus {
  border-color: #409EFF;
  outline: 0;
}



.tipdiaolog /deep/.el-dialog {

  width: 400px;
  height: 260px;
  background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}

/deep/.el-dialog__header {

  display: none;

}

/deep/.el-dialog__body {

  padding: 0;
}






.div-dialog-tip {
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
}

.close_dialog-tip {
  width: 36px;
  height: 36px;

  align-self: flex-end;
  position: absolute;
  right: 12px;

}

.dialog-head-tip {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.dialog-head-p-tip {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

}

.dialog-content-tip {
  display: flex;
  width: 100%;
  flex: auto;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;


}

.dialog-bottom-tip {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 19px;
  margin-right: 19px;
}

.dialog-negative {
  flex: auto;
  height: 52px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 52px;
  text-align: center;
}



.dialog-positive {
  flex: auto;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  border: none;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin-left: 15px;
}
</style>